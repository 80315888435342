<template>
  <v-container fluid class="lookup-container">
    <v-row>
      <v-col cols="12">
        <!-- <v-card class="card-wrapper"></v-card> -->
        <div v-if="!loader" class="dynamicDiv" v-html="withBrTags"></div>
        <div v-else class="dynamicDiv">
          <div class="col-md-12 mt-3 pl-0">
            <div class="card">
              <v-icon color="primary">fas fa-spinner fa-spin</v-icon>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-footer color="secondary" class="lookup-footer" height="62px">
      <div class="footer-inner">
        <div class="btn-wrapper">
          <v-btn
            color="primary"
            @click="submitLookup()"
            class="submit-btn mr-2"
            outlined
            rounded
            >Confirm</v-btn
          >
        </div>
        <div
          class="commands-wrapper"
          @click="inputFocused = true"
          v-click-outside="{
            handler: onClickOutside,
          }"
        >
          <v-list
            dense
            :elevation="1"
            class="commands-list"
            v-if="items.length > 0 && inputFocused"
          >
            <v-list-item
              v-for="item in items"
              :key="item"
              rounded
              @click="appendItem(item)"
              class="cursor-pointer"
            >
              <span> {{ item }}</span>
            </v-list-item>
          </v-list>
          <v-text-field
            label="Type command.."
            single-line
            filled
            dense
            rounded
            v-model="selectString"
            @keyup="filter()"
            @keydown="handleKbShortcut"
            class="lookup-input-field"
          ></v-text-field>
        </div>
        <v-dialog
            v-model="clearHistoryDialog"
            persistent
            max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ml-2"
                fab
                dark
                x-small
                color="error"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon dark>
                fas fa-trash
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Delete History
            </v-card-title>
            <v-card-text>Confirm to clear your search history.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="clearHistoryDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="green darken-1"
                  text
                  @click="clearLookupHistory"
              >
                Clear History
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-footer>
  </v-container>
</template>
<script>
import { getData } from "@/api/lookup/lookup";
import { lookup, clearHistory } from "@/api/lookup/lookup";
import { Errors } from "@/utils/error";
export default {
  data() {
    return {
      inputFocused: false,
      selectString: "",
      items: [],
      cachedItems: [],
      parameterSubmitted: false,
      integrationSubmitted: false,
      commandSubmitted: false,
      lookupResult: [],
      integrationParameters: [],
      cachedIntegrationParameters: [],
      rawResultHTML: "",
      loader: false,
      clearHistoryDialog: false,
    };
  },
  computed: {
    withBrTags: function () {
      let html = this.rawResultHTML;
      /* Removes /n  */
      html = html.replaceAll(/(\\r)*\\n/g, " ");
      /* removes backslashes */
      html = html.replace(/\\/g, "");
      /* removes doublequotes surrrounding whole html */
      html = html.replace(/^"(.*)"$/, "$1");
      return html;
    },
  },
  created() {
    this.showData();
  },
  methods: {
    // Submit on shift + enter
    handleKbShortcut(e) {
      if (e.shiftKey) {
        if (e.keyCode === 13) {
          this.submitLookup();
        }
      }
    },
    onClickOutside() {
      this.inputFocused = false;
    },
    hasTwoWords(string) {
      let result = string.split(" ", 2);
      return !!result[1];
    },
    checkCompletion(type) {
      if (type === "parameter") {
        return this.cachedItems.some((substring) =>
          this.selectString.includes(substring)
        );
      }
      if (type === "integration") {
        const splitSelectString = this.selectString.split(" ", 2);
        if (splitSelectString[1]) {
          return this.cachedIntegrationParameters.some((substring) =>
            splitSelectString[1].includes(substring)
          );
        }
      }
    },
    appendItem(item) {
      if (this.parameterSubmitted) {
        if (this.hasTwoWords(this.selectString)) {
          // removing second word before appending
          this.selectString = this.selectString.replace(/[\W]*\S+[\W]*$/, "");
          this.selectString = this.selectString + " " + item;
        } else {
          // removing white and trailing space
          this.selectString = this.selectString.trim().replaceAll(" +", " ");
          this.selectString = this.selectString + " " + item;
        }
      } else {
        this.selectString = item;
      }
      this.filter();
    },
    filter() {
      // fetch data if user deleted all input
      if (this.selectString.length === 0) {
        this.showData();
        this.commandSubmitted = false;
        this.integrationSubmitted = false;
        this.parameterSubmitted = false;
      } else if (!this.hasTwoWords(this.selectString)) {
        this.integrationSubmitted = false;
      }

      if (this.checkCompletion("integration")) {
        this.integrationSubmitted = true;
      } else {
        this.integrationSubmitted = false;
        this.commandSubmitted = false;
      }

      if (this.checkCompletion("parameter")) {
        this.parameterSubmitted = true;
      } else {
        this.parameterSubmitted = false;
        this.commandSubmitted = false;
      }
      // check for whole command completion
      if (this.parameterSubmitted && this.integrationSubmitted) {
        this.items = [];
        this.commandSubmitted = true;
      } else {
        this.commandSubmitted = false;

        // first filter
        if (!this.parameterSubmitted) {
          this.items = this.cachedItems.filter((item) =>
            item.includes(this.selectString)
          );
        } else {
          this.items = [];
          // Second filter for integrationParameters
          const filtered = Object.keys(this.integrationParameters)
            .filter((key) => this.selectString.includes(key))
            .reduce((obj, key) => {
              obj[key] = this.integrationParameters[key];
              return obj;
            }, {});
          let filteredArray = Object.values(filtered);
          this.items = [];
          // splitting integration parameter from input string
          this.items = Object.values(filtered)[0].filter(x => x !== null);
          this.cachedIntegrationParameters = this.items;
          if (this.hasTwoWords(this.selectString) && this.items.length !== 0) {
            const integration = this.selectString.split(" ", 2);
            this.items = filteredArray[0].filter((item) =>
              item.includes(integration[1])
            );
          }
        }
      }
    },
    showData() {
      getData()
        .then((response) => {
          this.items = [];
          this.integrationParameters = [];
          const self = this;
          response.data.integrations.forEach(function (value) {
            self.items.push(value);
          });
          this.cachedItems = this.items;
          this.integrationParameters = response.data.parameters;
          // An array of integration parameters values for filtering
          this.cachedIntegrationParameters = Object.values(
            response.data.parameters
          );
          // Flattening array
          this.cachedIntegrationParameters = this.cachedIntegrationParameters.reduce(
            (a, b) => a.concat(b),
            []
          );
        })
        .catch((e) => {
          Errors.show(e);
        });
    },
    submitLookup() {
      this.loader = true;
      this.parameterSubmitted = true;
      const data = {
        value: this.selectString,
      };
      lookup(data)
        .then((response) => {
          if (response.state === true) {
            this.rawResultHTML = response.message;
          }
          this.loader = false;
        })
        .catch((e) => {
          Errors.show(e);
          this.loader = false;
        });
    },
    clearLookupHistory() {
      this.clearHistoryDialog = false
      clearHistory()
          .then((response) => {
            this.$toast.open({
              message: response.message,
              type: response.type,
              position: "top-right",
              duration: 2000,
            });
            this.rawResultHTML = ""
          })
          .catch((e) => {
            Errors.show(e);
            this.loader = false;
          });
    },
  },
};
</script>

<style lang="scss">
.lookup-container {
  .lookup-input-field {
    .v-input__slot {
      margin: 0 !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.footer-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  height: 62px;
  bottom: -12px;
}

.lookup-container {
  position: relative;
  height: 100%;
}
.router-view-block {
  padding: 0 !important;
}
.v-main {
  padding: 0 !important;
}

.lookup-footer {
  position: sticky;
  width: calc(100% + 40px);
  margin-left: -20px;
  left: 0;
  box-sizing: border-box;
  top: 100%;
  margin-bottom: -12px;
  font-family: RobotoCondensedRegular, sans-serif !important;
  /* padding-left: 200px; */
}

.footer-inner {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.btn-wrapper {
  margin-left: 10px;
}
/* .container {
  padding: 12px;
} */
/* .card {
  width: 100%;
  margin-bottom: 100px;
}
.card-wrapper {
  box-shadow: none !important;
  padding: 0;
  margin: 0;
  margin-bottom: 80px;
  background-color: transparent !important;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}

.synapsa-lookup-table {
  width: 100%;
} */

.combobox-select-item {
  color: black;
}
.submit-btn {
  height: 39px !important;
  border-radius: 15px;
  text-transform: capitalize;
  font-family: UbuntuBold, sans-serif;
}
.lookup-input-field {
  border-radius: 15px;
  box-shadow: inset 6px 6px 20px -8px rgb(0 0 0 / 10%);
}

.lookup-input-field.theme--dark {
  border-radius: 15px;
  box-shadow: inset 20px 16px 30px -30px rgb(0 0 0 / 50%);
}

.lookup-input-field ::v-deep {
  & .v-text-field__details {
    display: none !important;
  }
}
.theme--dark.v-list {
  background: #313231 !important;
}
.commands-wrapper {
  position: relative;
  width: 100%;
}
.commands-list {
  position: absolute;
  border-radius: 15px;
  bottom: 50px;
  width: 100%;
  padding: 10px;
}

.v-text-field__details {
  display: none;
}

.card-wrapper ::v-deep {
  & thead tr {
    font-family: UbuntuBold, sans-serif;
  }

  & td {
    font-family: RobotoCondensedRegular, sans-serif !important;
  }
}

/* .card-wrapper.theme--dark ::v-deep {
  & .card {
    color: white;
    background-color: #313231;
    font-family: RobotoCondensedRegular, sans-serif !important;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 20px 20px;
  }
  & table {
    width: 100%;
    border-collapse: collapse;
  }
  & th {
    text-align: left;
    padding: 5px;
    background-color: #313231;
    font-size: 0.7rem;
  }
  & tr {
    padding: 10px;
    font-size: 0.875rem;
  }

  & tr:nth-child(odd) {
    background-color: #434545;
  }
  & td {
    padding: 5px;
  }
  & caption {
    padding-bottom: 10px;
    margin-right: 50px;
  }
  & iframe {
    height: 500px !important;
  }
  & iframe + p {
    margin-right: 50px;
  }
  & ul {
    list-style-type: none;
  }
  & .text-danger {
    color: #dc3545;
  }
  & .text-warning {
    color: #ffc107;
  }
  & .text-success {
    color: #28a745;
  }
  & .badge-success {
    background: #28a745;
    color: #ffffff;
  }
  & .badge {
    line-height: 1.5;
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
  }
  & h5 {
    font-size: 20px;
  }
  & .border > ul > li {
    padding-bottom: 10px;
  }
  & .border {
    border: 1px solid #dee2e6 !important;
    padding: 20px 10px;
    width: 50%;
  }
  & ul {
    padding-left: 0;
  }
} */

/* .card-wrapper.theme--light ::v-deep {
  & .card {
    color: black;
    background-color: #ffffff6e;
    box-shadow: 13px 13px 30px rgba(0, 0, 0, 0.1);
    font-family: RobotoCondensedRegular, sans-serif !important;
    border-radius: 15px;
    padding: 20px 20px;
  }
  & table {
    width: 100%;
    border-collapse: collapse;
  }
  & th {
    text-align: left;
    padding: 5px;
    font-size: 0.7rem;
  }
  & thead tr {
    background-color: #ffffff6e !important;
  }
  & tr {
    padding: 10px;
    font-size: 0.875rem;
  }
  & tr:nth-child(odd) {
    background-color: #fff;
  }
  & td {
    padding: 5px;
  }
  & caption {
    padding-bottom: 10px;
    margin-right: 50px;
  }
  & iframe {
    height: 500px !important;
  }
  & iframe + p {
    margin-right: 50px;
  }
  & ul {
    list-style-type: none;
  }
  & .text-danger {
    color: #dc3545;
  }
  & .text-warning {
    color: #ffc107;
  }
  & .text-success {
    color: #28a745;
  }
  & .badge-success {
    background: #28a745;
    color: #ffffff;
  }
  & .badge {
    line-height: 1.5;
    padding: 4px;
    border-radius: 4px;
    display: inline-block;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
  }
  & h5 {
    font-size: 20px;
  }
  & .border > ul > li {
    padding-bottom: 10px;
  }
  & .border {
    border: 2px solid #dee2e6 !important;
    padding: 20px 10px;
    width: 50%;
  }
  & ul {
    padding-left: 0;
  }
} */
.theme--dark .dynamicDiv ::v-deep{
  thead {
    background-color: transparent;
    border-bottom: 2px solid #fff;
  }
  tbody{
    tr {
      background-color: #5e5e6099;
    }
    tr:not(:last-child) {
      border-bottom: thin solid hsla(0,0%,100%,.12);
    }
  }
}
.theme--light .dynamicDiv ::v-deep{
  thead {
    background-color: transparent;
    border-bottom: 2px solid #3d3d3f;
  }
  tbody{
    tr {
      background-color: #fff;
    }
    tr:not(:last-child) {
      border-bottom: thin solid rgba(0,0,0,.12);
    }
  }
}
</style>
