import request from "@/utils/request";

export function getData() {
  return request({
    url: "api/lookup",
    method: "get",
  });
}

export function lookup(data) {
  return request({
    url: "api/lookup",
    method: "post",
    data: data,
  });
}

export function clearHistory() {
  return request({
    url: "api/lookup",
    method: "delete"
  });
}
